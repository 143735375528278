export const RETURN_INVESTMENT_GRAPH_PAGE = 1
export const LUMP_SUM_AND_MULTIPLE_PAYOUT_GRAPH_PAGE = 2

export const MARRIED = "M"
export const SINGLE = "S"

export const GENDER_FEMALE = "F"
export const GENDER_MALE = "M"

export const LUMP_SUM_PAYOUT = "L"
export const MULTIPLE_PAYOUTS = "M"

export const QUALIFIED_PURCHASER = "QP"
export const ACCREDITED_INVESTOR = "AI"
export const MASS = "M"

export const SINGLE_PURCHASER = "S"
export const COUPLE_PURCHASER = "C"

export const IS_US_CITIZEN_YES = "Y"
export const IS_US_CITIZEN_NO = "N"

export const IS_GREEN_CARD_YES = "Y"
export const IS_GREEN_CARD_NO = "N"

export const VOO_VANGUARD = "VOO Vanguard"

export const COUNTRY = "US"
export const CONTRACTS_QTY = [25, 50, 100, -1]
export const DEFAULT_CONTRACTS_PER_PAGE = 25

export const DEFAULT_CLIENT_AGE = 55
export const DEFAULT_CLIENT_GENDER = "Male"
export const DEFAULT_AVERAGE_MARKET_RETURN = 8
export const DEFAULT_CLIENT_PAYOUT_AGE = 75
export const DEFAULT_INVESTMENT_AMOUNT = 50000

import * as Sentry from "@sentry/react"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { useIdleTimer } from "react-idle-timer"
import { useState, useEffect } from "react"
import { Landing, Login, Register, PageNotFound } from "@pages"
import { useAppContext } from "@context"
import { PublicEstimator } from "@components/estimator"
import { IdleModal } from "@components"
import { PageLayout } from "@layouts"

function App() {
  const [remaining, setRemaining] = useState(900000)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { instance } = useMsal()
  const { homeAccountId } = useAppContext()

  const logout = (instance) => {
    instance.logoutRedirect({
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: "/"
    })
  }

  const onIdle = () => {
    setIsModalOpen(false)
    if (homeAccountId) logout(instance)
  }

  const onActive = () => {
    setIsModalOpen(false)
  }

  const onPrompt = () => {
    if (homeAccountId) setIsModalOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: 900000,
    promptBeforeIdle: 40_000,
    throttle: 1000
  })

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  return (
    <>
      <AuthenticatedTemplate>
        <PageLayout />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/public/estimator" element={<PublicEstimator />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </UnauthenticatedTemplate>
      <IdleModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        remaining={remaining}
        handleStillHere={handleStillHere}
        logout={logout}
        instance={instance}
      />
      <ToastContainer />
    </>
  )
}

export default Sentry.withProfiler(App)
